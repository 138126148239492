import { AnyAction } from '@reduxjs/toolkit';
import { modalsSlice as slice } from '../slices/modals.slice';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setAttachmentModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setAttachmentModal(data));
  }
};

export const setCalendarEventHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarEventHandleModal(data));
  }
};

export const setCalendarFiltersModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarFiltersModal(data));
  }
};

export const setCalendarGalleryModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarGalleryModal(data));
  }
};

export const setCalendarSettingsModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarSettingsModal(data));
  }
};

export const setCameraModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCameraModal(data));
  }
};

export const setClassModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClassModal(data));
  }
};

export const setClassbookDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClassbookDetailModal(data));
  }
};

export const setClassbookHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClassbookHandleModal(data));
  }
};

export const setClubDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClubDetailModal(data));
  }
};

export const setCommunicationChangeThreadNameModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommunicationChangeThreadNameModal(data));
  }
};

export const setCommunicationGalleryModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommunicationGalleryModal(data));
  }
};

export const setCommunicationMessageViewedModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommunicationMessageViewedModal(data));
  }
};

export const setConfirmModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setConfirmModal(data));
  }
};

export const setCookiesModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCookiesModal(data));
  }
};

export const setDashboardHandleWidgetModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setDashboardHandleWidgetModal(data));
  }
};

export const setDashboardSettingsModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setDashboardSettingsModal(data));
  }
};

export const setDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setDetailModal(data));
  }
};

export const setDiagnosticsModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setDiagnosticsModal(data));
  }
};

export const setDraftsModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setDraftsModal(data));
  }
};

export const setEmployeeModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setEmployeeModal(data));
  }
};

export const setExcuseNotesDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setExcuseNotesDetailModal(data));
  }
};

export const setExcuseNotesHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setExcuseNotesHandleModal(data));
  }
};

export const setFileInputModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setFileInputModal(data));
  }
};

export const setGalleryModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setGalleryModal(data));
  }
};

export const setChangelogModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setChangelogModal(data));
  }
};

export const setChildCardModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setChildCardModal(data));
  }
};

export const setChildDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setChildDetailModal(data));
  }
};

export const setChildrenModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setChildrenModal(data));
  }
};

export const setImageModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setImageModal(data));
  }
};

export const setInfoModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setInfoModal(data));
  }
};

export const setItemPickerModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setItemPickerModal(data));
  }
};

export const setLanguageModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setLanguageModal(data));
  }
};

export const setPhotoChangeModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setPhotoChangeModal(data));
  }
};

export const setPopupGalleryModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setPopupGalleryModal(data));
  }
};

export const setPostCreateModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setPostCreateModal(data));
  }
};

export const setPostDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setPostDetailModal(data));
  }
};

export const setPressReportTemplateHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setPressReportTemplateHandleModal(data));
  }
};

export const setReactionDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setReactionDetailModal(data));
  }
};

export const setShoppingCartModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setShoppingCartModal(data));
  }
};

export const setShopGalleryModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setShopGalleryModal(data));
  }
};

export const setShopItemDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setShopItemDetailModal(data));
  }
};

export const setShopItemVariantModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setShopItemVariantModal(data));
  }
};

export const setShopOrderDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setShopOrderDetailModal(data));
  }
};

export const setShopOrderStatusModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setShopOrderStatusModal(data));
  }
};

export const setSchoolUrlsModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSchoolUrlsModal(data));
  }
};

export const setSSOGetModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSSOGetModal(data));
  }
};

export const setSSOLoginQrCodeModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSSOLoginQrCodeModal(data));
  }
};

export const setStockCategoryDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockCategoryDetailModal(data));
  }
};

export const setStockCategoryHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockCategoryHandleModal(data));
  }
};

export const setStockItemDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockItemDetailModal(data));
  }
};

export const setStockItemHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockItemHandleModal(data));
  }
};

export const setStockTagDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockTagDetailModal(data));
  }
};

export const setStockTagHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockTagHandleModal(data));
  }
};

export const setSubstitutionsDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSubstitutionsDetailModal(data));
  }
};

export const setSubstitutionsHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSubstitutionsHandleModal(data));
  }
};

export const setTimelineCommentDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimelineCommentDetailModal(data));
  }
};

export const setTimelineGalleryModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimelineGalleryModal(data));
  }
};

export const setTimelinePollDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimelinePollDetailModal(data));
  }
};

export const setTimetableHourDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimetableHourDetailModal(data));
  }
};

export const setTimetableHourHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimetableHourHandleModal(data));
  }
};

export const setTimetableSubjectDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimetableSubjectDetailModal(data));
  }
};

export const setTimetableSubjectHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimetableSubjectHandleModal(data));
  }
};

export const setTimetableRoomDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimetableRoomDetailModal(data));
  }
};

export const setTimetableRoomHandleModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimetableRoomHandleModal(data));
  }
};

export const setUserSettingsErrorModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setUserSettingsErrorModal(data));
  }
};

export const setWarningModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setWarningModal(data));
  }
};

export const setWageDetailModal = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setWageDetailModal(data));
  }
};

export const resetPostCreateModal = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetPostCreateModal());
  }
};

export const resetAllModals = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetAllModals());
  }
};